import PopupCloseIconButtonComponent from '@wix/thunderbolt-elements/src/components/PopupCloseIconButton/viewer/PopupCloseIconButton';


const PopupCloseIconButton = {
  component: PopupCloseIconButtonComponent
};


export const components = {
  ['PopupCloseIconButton']: PopupCloseIconButton
};

